import {useMemo} from 'react';

import Header from '@/components/brochureV2/section/Header/Header';
import {HeaderBackgroundColorScheme} from '@/enums';
import {useSharedTranslations, useTranslations} from '@/hooks/useTranslations';
import {useSiteData} from '@/hooks/useSiteData';
import {type HydratedT} from '@/components/shared/Page/I18N';
import type {Site} from '@data/types';
import type {LinkComponent} from '@/types';
import {BLOG_PAGE_TYPE} from '@/components/blog/types';
import {partnersBlogSubNav} from '@/components/pages/partners/blog/PartnersBlogSubNav/PartnersBlogSubNav';
import {retailBlogSubNav} from '@/components/pages/retail/RetailBlogSubNav/RetailBlogSubNav';
import {ShopifyLogoPartners} from '@/components/brochureV2/section/Header/svg/ShopifyLogoClient';
import {getPartnersMenuItems} from '@/components/pages/blog/BlogSubNav/utils';
import {
  PartnersCtaLinkList,
  PartnersMobileCtaLinkList,
  PartnersMobileMenuCtaLinkList,
} from '@/components/pages/blog/BlogSubNav/PartnersCtaLinkList';

import {NavigationSearch} from '../NavigationSearch/NavigationSearch';

import {enSubNav} from './BlogSubNav.en';
import {esSubNav} from './BlogSubNav.es';
import {jaSubNav} from './BlogSubNav.ja';
import {itSubNav} from './BlogSubNav.it';
import {frSubNav} from './BlogSubNav.fr';
import {deSubNav} from './BlogSubNav.de';
import {nlSubNav} from './BlogSubNav.nl';
import {ptBRSubNav} from './BlogSubNav.pt-BR';
import {zhCNSubNav} from './BlogSubNav.zh-CN';
import {krSubNav} from './BlogSubNav.ko';

interface SubNavMap {
  [key: string]: (
    t: HydratedT,
    localizePath: (path: string) => string,
    site: Site,
    enBlogPath: string,
    variant?: string,
  ) => () => {
    sectionTitle: string;
    sectionOverviewUrl: string | null;
    pageTitle: string;
    links: LinkComponent[];
    overviewText?: string;
  };
}

const SUB_NAV_MAP: SubNavMap = {
  en: enSubNav,
  es: esSubNav,
  ja: jaSubNav,
  it: itSubNav,
  fr: frSubNav,
  de: deSubNav,
  nl: nlSubNav,
  'pt-BR': ptBRSubNav,
  'zh-CN': zhCNSubNav,
  ko: krSubNav,
};

const localizedSubNav = (locale: string) => {
  return (
    SUB_NAV_MAP[locale] ||
    SUB_NAV_MAP[locale.split('-')[0]] ||
    SUB_NAV_MAP['en']
  );
};

interface BlogSubNavProps {
  enBlogPath: string;
  blogPageType?: BLOG_PAGE_TYPE;
  subNavVariant?: string;
}

export function BlogSubNav({
  enBlogPath,
  blogPageType,
  subNavVariant,
}: BlogSubNavProps) {
  const {t: sharedT} = useSharedTranslations(`pages${enBlogPath}blogSubNav`);
  const {t, localizePath} = useTranslations();

  const {site} = useSiteData();

  const partnersHeaderProps = useMemo(() => {
    return (
      blogPageType === BLOG_PAGE_TYPE.PARTNERS && {
        mainNavFn: getPartnersMenuItems,
        CustomDesktopCtaLinklist: <PartnersCtaLinkList />,
        CustomMobileCtaLinklist: <PartnersMobileCtaLinkList />,
        CustomMobileMenuCtaLinklist: <PartnersMobileMenuCtaLinkList />,
        logo: <ShopifyLogoPartners />,
      }
    );
  }, [blogPageType]);

  const secondaryNavFn = useMemo(() => {
    switch (blogPageType) {
      case BLOG_PAGE_TYPE.BLOG:
        return localizedSubNav(site.locale)(
          sharedT,
          localizePath,
          site,
          enBlogPath,
          subNavVariant,
        );
      case BLOG_PAGE_TYPE.PARTNERS:
        return partnersBlogSubNav(sharedT, localizePath, enBlogPath);
      case BLOG_PAGE_TYPE.RETAIL:
        return retailBlogSubNav(sharedT, localizePath, enBlogPath);
    }
  }, [blogPageType, enBlogPath, localizePath, sharedT, site, subNavVariant]);

  return (
    <Header
      background={HeaderBackgroundColorScheme.White}
      navigationSearch={
        <NavigationSearch
          enBlogPath={enBlogPath}
          searchText={t('global:globalNav.searchText')}
          searchPlaceholder={t('global:globalNav.searchPlaceholder')}
          searchPlaceholderMobile={t(
            'global:globalNav.searchPlaceholderMobile',
          )}
        />
      }
      secondaryNavFn={secondaryNavFn}
      {...partnersHeaderProps}
    />
  );
}
